import React from "react";
import ep3 from "../assets/ep3.png";
import ep4 from "../assets/ep4.png";
import ep5 from "../assets/ep5.png";

const episodes = [
  {
    imagen: ep3,
    description:
      "Felipe Machado, de Kinesiólogo a Programador, comparte su historia de transición hacia IT y cómo enfrentar con éxito los desafíos de un mercado tecnológico en constante cambio.",
    link: "https://www.youtube.com/watch?v=HITIpav32ak",
  },
  {
    imagen: ep4,
    description:
      "Ileana Barrionuevo, Ingeniera en Sistemas, nos guía en los primeros pasos hacia una carrera en ciberseguridad, explorando roles clave y oportunidades en este campo.",
    link: "https://www.youtube.com/watch?v=GSn2oU3lHhk",
  },
  {
    imagen: ep5,
    description:
      "Martina Fumiere, abogada especializada en derecho digital y nuevas tecnologías, nos guía en el derecho en la era tecnológica, abordando temas como jurisprudencia, copyright y el uso de inteligencia artificial en la justicia.",
    link: "https://youtu.be/FiMw5zbAKQ8",
  },
];

const Cards = () => {
  return (
    <section data-testid="episodios-seccion" className="py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-3 gap-6">
        {episodes.map((episode, index) => (
          <div
            key={index}
            className="relative bg-gray-800 rounded-lg shadow-md overflow-hidden hover:shadow-2xl transform hover:-translate-y-2 transition-transform duration-300 ease-in-out"
          >
            {/* Imagen Responsiva */}
            <img
              src={episode.imagen}
              alt={`Episodio ${index + 3}`}
              className="w-full h-auto object-cover"
            />
            {/* Descripción */}
            <div className="p-4 mt-4 flex flex-col items-center">
              <p
                className="text-gray-300 text-md mb-4"
                style={{ fontFamily: "'Inter', sans-serif", fontWeight: 400 }}
              >
                {episode.description}
              </p>

              <a
                href={episode.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-white text-black text-lg py-2 px-6 rounded-full hover:bg-gray-800 hover:text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out"
              >
                Escuchar
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Cards;
