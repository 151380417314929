import React from "react";

const AboutUs = () => {
  return (
    <section id="about-us" className="bg-dark py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center pt-10 pb-10">
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-5">
            Bienvenidos a Somos Nodos
          </h2>
          <p className="text-base sm:text-lg text-gray-300 mb-6 text-justify leading-relaxed">
            <strong>Somos Nodos</strong> es un espacio donde compartimos historias de vida y aprendizajes reales. A través de conversaciones auténticas, conectamos con personas de distintos caminos que nos invitan a escuchar, reflexionar y aprender algo nuevo.
          </p>
          <p className="text-base sm:text-lg text-gray-300 mb-6 text-justify leading-relaxed">
            Nuestra misión es ser un punto de encuentro para voces diversas, con o sin títulos, que comparten sus experiencias y aprendizajes. Creemos en el poder de las historias reales para inspirar, enfrentar desafíos y fomentar la reflexión.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
